import React from "react";
import Date from "../Date";
import style from "../header.module.css";

const Sponsorship=()=>{
  return (
    <div>
      <h2 class={style.abtHeader}>Sponsorship</h2>
      <hr />
      <div className={style.flex}>
        <div className={style.abtInstCont}>
          <h4>INVITATION LETTER FOR SPONSORSHIP</h4> <br></br>
          <b>Dear Technology Leader,</b>
          <br />
          <br />
          <p style={{ fontSize: 15, letterSpacing: 1 }}>
            In today's rapidly evolving digital landscape, where data-driven
            technologies and machine learning are reshaping industries,
            collaboration between academia and industry is crucial to drive
            meaningful innovation. As businesses and research institutions work
            together to push the boundaries of artificial intelligence, data
            science, and analytics, knowledge exchange and collaboration
            platforms become more vital than ever.With this vision, we are
            excited to announce the{" "}
            <span className={style.highlight}>
              International Conference on Innovative Computing & Communication
              (ICICC 2026)
            </span>
            , which will take place on{" "}
            <span className={style.highlight}>
              Feb 06th-07th, 2026, at Shaheed Sukhdev College of Business
              Studies, Delhi University, Delhi, India
            </span>
            . This prestigious conference aims to bring together researchers,
            academicians, industry experts, and practitioners to discuss the
            latest advancements in Computing, data analytics, artificial
            intelligence, and machine learning.
          </p>{" "}
          <p style={{ fontSize: 16, letterSpacing: 1, fontWeight: 700 }}>
            Conference Objectives:
          </p>
          <p style={{ fontSize: 15, letterSpacing: 1 }}>
            The primary objectives of <b>ICICC 2026</b> include:
          </p>
          <ul>
            <li>
              Facilitating knowledge exchange between researchers, developers,
              engineers, and industry professionals
            </li>
            <li>
              Showcasing the latest innovations in{" "}
              <b>
                Computing, Networking, AI, machine learning, data analytics, and
                big data applications
              </b>
            </li>
            <li>
              Bridging the gap between industry challenges and academic research
              to foster real-world solutions
            </li>
            <li>
              Promoting national and international collaboration between
              universities, research institutions, and industry leaders
            </li>
          </ul>
          <p style={{ fontSize: 15, letterSpacing: 1 }}>
            Hosted by <b>SSCBS, Delhi,</b> a globally recognised institution
            known for fostering innovation and research excellence, ICICC 2026
            will feature high-quality technical discussions, keynote sessions,
            and interactive workshops covering key topics such as:
          </p>
          <ul>
            <li>Artificial Intelligence and Machine Learning</li>
            <li>Innovative Computing & Communication</li>
            <li>Deep Learning and Computer Vision</li>
            <li>Natural Language Processing and Speech Recognition</li>
            <li>Data Privacy, Security, and Ethical AI</li>
          </ul>
          <p style={{ fontSize: 16, letterSpacing: 1, fontWeight: 700 }}>
          Why Sponsor ICICC 2026?
          </p>
          <p style={{ fontSize: 15, letterSpacing: 1 }}>
            As a pioneering force in the industry, <b>ICICC 2026</b> provides an
            exclusive platform for your organization to:
          </p>
          <ul>
            <li>
              Showcase your company’s vision, technologies, and solutions to a
              highly engaged audience.
            </li>
            <li>
              Network with leading academics, researchers, and industry
              professionals shaping the future of AI and data science.
            </li>
            <li>
              Gain Visibility through strategic branding and promotional
              opportunities.
            </li>
            <li>
              Collaborate with top-tier talent, fostering innovation and
              industry-academia partnerships
            </li>
          </ul>
          <p style={{ fontSize: 15, letterSpacing: 1 }}>
          We invite you to explore our <b>sponsorship packages</b> and collaborate with us to make <b>ICICC 2026</b> a landmark event in Computing & Communication. Your support will not only contribute to the success of this conference but also help drive forward cutting-edge research and industry advancements<br/>We look forward to your valued partnership.
          </p>
          <br />
          <br />
          <b>
            Yours Sincerely,
            <br />
            General Chair<br/>ICICC 2026 | Shaheed Sukhdev College of Business Studies, Delhi University, 
Delhi


          </b>
          <br />
          <br />
          <a href={require("./Sponsorship.docx")}>Sponsorship</a>
          <br />
          <br />
        </div>
        <Date />
      </div>
    </div>
  );
};

export default Sponsorship;
