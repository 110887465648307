import React from "react";
import "./AuthorGuidelines.css";

const AuthorGuidelines = () => {
  return (
    <div className="guidelines-container">
      <h1 className="guidelines-header">Author Guidelines for Springer Publications</h1>
      
      <div className="guideline-section">
        <h2>1. Manuscript Format:</h2>
        <ul>
          <li>Papers must be prepared in Springer’s LNNS (Lecture Notes in Networks & System) format - <a href="https://icicc-conf.com/downloads" target="_blank" rel="noopener noreferrer">Download Templates</a>.</li>
          <li>Templates for LaTeX and MS Word are available on Springer’s official website.</li>
        </ul>
      </div>

      <div className="guideline-section">
        <h2>2. Paper Length:</h2>
        <ul>
          <li>Standard length is 10–12 pages (including references and appendices).</li>
          <li>Additional pages may be subject to extra charges.</li>
        </ul>
      </div>

      <div className="guideline-section">
        <h2>3. Plagiarism Policy:</h2>
        <ul>
          <li>Less than 15% similarity as per Turnitin/iThenticate.</li>
          <li>Papers exceeding this limit will be rejected without review.</li>
        </ul>
      </div>

      <div className="guideline-section">
        <h2>4. Content Requirements:</h2>
        <ul>
          <li>Original, unpublished research relevant to the conference tracks.</li>
          <li>Proper citations must be provided for all referenced works.</li>
        </ul>
      </div>

      <div className="guideline-section">
        <h2>5. Authorship and Affiliations:</h2>
        <ul>
          <li>Author list should include full names, affiliations, and email addresses.</li>
          <li>At least one author must register and present at the conference.</li>
        </ul>
      </div>

      <div className="guideline-section">
        <h2>6. Figures and Tables:</h2>
        <ul>
          <li>Figures should be of high resolution (at least 300 dpi).</li>
          <li>Tables and figures must be properly labeled and cited.</li>
        </ul>
      </div>

      <div className="guideline-section">
        <h2>7. Reference Style:</h2>
        <ul>
          <li>Use Springer LNNS referencing format for citations.</li>
          <li>Ensure all references are properly formatted and included in the bibliography.</li>
        </ul>
      </div>
    </div>
  );
};

export default AuthorGuidelines;
