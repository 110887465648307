const navConfig = [
    { label: "Home", path: "/" },
    {
        label: "About",
        dropdown: [
            { label: "About Institute", path: "/about_institute" },
            { label: "About Universal Innovators", path: "/about_universal_innovators" },
            { label: "Policy", path: "/policy" }
        ]
    },
    {
        label: "Papers",
        dropdown: [
            { label: "Call for Papers", path: "/call_for_papers" },
            { label: "Paper Submission", path: "/paper_submission" },
            { label: "Call for Special Session", path: "/call_for_special_sessions" },
            { label: "Call for International Workshops", path: "/call_for_international_workshops" },
            { label: "Poster Paper Presentation", path: "/call_for_posters" }
        ]
    },
    { label: "Submission Guidelines", dropdown: [
        { label: "Author Guidelines", path: "/author-guidelines" },
        { label: "Submission Guidelines On Microsoft CMT", path: "/submission-guidelines" }     
       
    ]
    },        
    { label: "Special Sessions", path: "/specialsessions" },
    // { label: "Workshops", path: "/workshops" },
    { label: "Registration", path: "/registrations" },
    { label: "Sponsorships", path: "/sponsorship" },
    { label: "Publications", path: "/publications" },
    {
        label: "Committee",
        dropdown: [
            { label: "Steering Committee", path: "/steercom" },
            { label: "Technical Program Committee", path: "/techcom" },
            { label: "Advisory Committee", path: "/advcom" },
            { label: "Industry Experts", path: "/indexp" }
        ]
    },
    { label: "Awards", dropdown: [
            { label: "UILA 2026", path: "/awards" },
            { label: "UILA 2025", path: "/uila25" },
            { label: "UILA 2024", path: "/uila24" }
            
           
        ]
        },
    { label: "Conference Venue", path: "/venue" },
    { label: "Downloads", path: "/downloads" },
    {
        label: "Previous Conferences",
        dropdown: [
            { label: "ICICC 2025", path: "/icicc25" },
            { label: "ICICC 2024", path: "/icicc24" },
            { label: "ICICC 2023", path: "/icicc23" },
            { label: "ICICC 2022", path: "/icicc22" },
            { label: "ICICC 2021", path: "/icicc21" },
            { label: "ICICC 2020", path: "/icicc20" },
            { label: "ICICC 2019", path: "/icicc19" },
            { label: "ICICC 2018", path: "/icicc18" }
        ]
    }
    // {
    //     label: "Previous UILA Awards",
    //     dropdown: [
    //         { label: "UILA AWARDS 2025", path: "/uila25" },
    //         { label: "UILA AWARDS 2024", path: "/uila24" }
    //     ]
    // }
];

export default navConfig;
