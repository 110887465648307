import React from "react";
import style from "../header.module.css";
import Date from '../Date'

const Workshops=()=>{
  return(
  <div>
  <h2 class={style.abtHeader}>INTERNATIONAL WORKSHOPS ON</h2>
  <hr />
  <Date/>
  
  </div>

 
);
};

export default Workshops;
