import "./SubmissionGuidelines.css";

export default function SubmissionGuidelines() {
  return (
    <div className="submission-guidelines-container">
      <h1>Submission Guidelines via Microsoft CMT Portal</h1>
      
      <div className="guideline-section">
        <h2>1. Account Registration:</h2>
        <ul>
          <li>Authors must create an account on Microsoft CMT if they do not already have one.</li>
          <li>The submission link will be available on the official ICICC 2026 website.</li>
        </ul>
      </div>
      
      <div className="guideline-section">
        <h2>2. Paper Submission Steps:</h2>
        <ul>
          <li>Log in to Microsoft CMT and select INTERNATIONAL CONFERENCE ON INNOVATIVE COMPUTING AND COMMUNICATION (ICICC- 2026) as the conference.</li>
          <li>Click on "Create New Submission" and enter paper details (title, abstract, keywords).</li>
          <li>Upload the PDF version of the manuscript in Springer format.</li>
          <li>Add all co-authors with their affiliations and email addresses.</li>
        </ul>
      </div>
      
      <div className="guideline-section">
        <h2>3. Review Process:</h2>
        <ul>
          <li>Submissions will undergo a double-blind peer review process.</li>
        </ul>
      </div>
      
      <div className="guideline-section">
        <h2>4. Final Submission (Camera-Ready Paper):</h2>
        <ul>
          <li>Accepted papers must be revised in accordance with reviewer comments.</li>
          <li>Authors must submit the camera-ready version in both PDF and source (LaTeX/Word) formats.</li>
          <li>A signed Springer Copyright Form must be uploaded with the final submission (Provided by the Conference Organizing Team) i.e., CTP.</li>
        </ul>
      </div>
      
      <div className="guideline-section">
        <h2>5. Conference Registration & Presentation:</h2>
        <ul>
          <li>At least one author must register for the conference before the deadline.</li>
          <li>Papers will be included in the Springer proceedings only if presented at the conference (either in-person or virtually).</li>
        </ul>
      </div>
    </div>
  );
}
