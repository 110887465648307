import React from 'react';
import style from "../header.module.css";
import { Alert } from 'react-bootstrap';

const awards = { 
    "Lifetime Achievement Award": "K. L Srivastava",
    "Best Principal Award": "Saurabh Aggarwal",
    "Best Researcher Award": "Shabir Ahmad Parah",
    "Lifetime Achievement Award": "Prasanalakshmi Balaji",
    "Multidisciplinary Research Award": "Saurabh Aggarwal",
    "National Research Award": "Ritu Saxena",
    "Young Researcher Award": "Arpitha T",
    "Best Paper Award": "Akshatha P S",
    "Research Achievement Award": "Dr. Sudhanshu Gonge",
    "Teaching Excellence Award": "Dr. Sudhanshu Gonge",
    "Research Achievement Award": "Dr. Sheshang Degadwala",
    "Multidisciplinary International Research Award": "Dr. Biswajit Brahma",
    "Best Ph.D. Thesis Award": "Dr. Sandipan Dhar"
};
const Uila25 = () => {
  return (
    <div className={style.awardsCont}>
    <h4>UNIVERSAL INOVATORS LEADERSHIP AWARDS 2025</h4>
    <hr />
    

      <div className="col-md-12">
        <div className="row">
          {Object.entries(awards).map(([award, awardee]) => (
            <div className="col-md-3">
            <Alert className={style.indAlert} key={award}>
              <h5 style={{ color: "#31708f" }}> {award} </h5>
              <hr />
              <h6 className={style.indCont}>
               {awardee}
              </h6>
            </Alert>
          </div>
           ))}
                 
        </div>
      </div>
    </div>
  );
}

export default Uila25