import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import navConfig from './navConfig'; // Import the config
import style from "../header.module.css";

function CommonNavbar() {
    const [expanded, setExpanded] = useState(false);

    const renderLinks = () => {
        return navConfig.map((item, index) => (
            item.dropdown ? (
                <NavDropdown
                    key={index}
                   title={
      <span className={style.breakText}>
        {item.label}
      </span>
    } 
                    id={`nav-dropdown-${index}`}
                    className={style.navDD}
                >
                    {item.dropdown.map((subItem, subIndex) => (
                        <NavDropdown.Item
                            key={subIndex}
                            onClick={() => setExpanded(false)}
                        >
                            <NavLink className={style.links} to={subItem.path}>
                                {subItem.label}
                            </NavLink>
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            ) : (
                <NavLink
                    key={index}
                    className={style.links}
                    to={item.path}
                    onClick={() => setExpanded(false)}
                >
                    <p className={`${style.navLink} ${style.breakText2}`}>{item.label}</p>
                </NavLink>
            )
        ));
    };

    return (
        <Navbar bg="light" variant="light" expand="lg" className="sticky-top" expanded={expanded}>
            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={() => setExpanded(expanded ? false : "expanded")}
                style={{'width':'100vw'}}
            />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto"
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        fontSize: '15px',
                        textAlign: 'center',
                        padding: '10px'
                    }}
                >
                    {renderLinks()}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default CommonNavbar;
